// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b2_kw";
export var caseStudyBackground__lineColor = "b2_kt";
export var caseStudyHead__imageAbsolute = "b2_kq";
export var caseStudyHead__imageWrapper = "b2_kp";
export var caseStudyProjectsSection = "b2_kx";
export var caseStudyQuote__bgLight = "b2_kD";
export var caseStudyQuote__bgRing = "b2_kr";
export var caseStudySolution__ring = "b2_kB";
export var caseStudyTech = "b2_kF";
export var caseStudy__bgDark = "b2_km";
export var caseStudy__bgLight = "b2_kl";